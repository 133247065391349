export default {
  BUTTON_PRIMARY_CLASS: 'btn btn-1',
  BUTTON_SECONDARY_CLASS: 'btn btn-2',
  GARAGE_TITLE: '',
  GARAGE_SIZE: '<span class="garage-size" key="garage-size">{{size}}</span>',
  PRODUCT_REPEATER_CLASS: 'd-grid grid-2 grid-md-3 grid-lg-3 grid-gap-md-30 grid-gap-15 cata-product cp-grid',

  SEARCH_BOX_CLASS: 'form-search navbar-form search',
  SEARCH_BOX_BUTTON_CLASS: 'search-icon',
  SEARCH_BOX_INPUT_CLASS: 'form-control',

  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  DISCARD_VEHICLE: 'Clear',
  CHANGE_VEHICLE: 'Change',
};
