
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatI1(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('div', { 'className': 'product-card' }, _createElement('div', { 'className': 'hover-2' }, _createElement('div', { 'className': 'product-card_header' }, _createElement('figure', { 'className': 'product-card_image' }, _createElement('a', { 'href': this.url }, _createElement('img', {
                    'className': 'featured-image front img-lazy blur-up lazyautosizes lazyloaded',
                    'alt': this.removeHTML(this.title),
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'onError': this.onImageError
                }))), _createElement('div', { 'className': 'product-card_label label_ui-group' }, this.out_of_stock ? _createElement('span', {
                    'className': 'label_ui label_ui-hot',
                    'key': '464'
                }, 'Sold Out') : null, this.on_sale ? _createElement('span', {
                    'className': 'label_ui label_ui-sale',
                    'key': '550'
                }, 'Sale') : null)), _createElement('div', { 'className': 'product-card_body' }, _createElement('div', { 'className': 'product-card_row' }, _createElement('small', { 'className': 'product-card_vendor' }, _createElement('a', Object.assign({}, {
                    'href': this.vendor_url,
                    'title': this.vendor
                }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('small', { 'className': 'product-card_sku' }, ' - ', _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.sku } }))), _createElement('h3', { 'className': 'product-card_name' }, _createElement('a', Object.assign({}, {
                    'href': this.url,
                    'aria-label': 'title'
                }, { dangerouslySetInnerHTML: { __html: this.title } })))), _createElement('div', { 'className': 'product-card_footer' }, this.on_sale ? _createElement('div', {
                    'className': 'product-price',
                    'key': '1259'
                }, _createElement('span', { 'className': 'price-sale' }, this.formatPrice(this.price)), _createElement('del', { 'className': 'price-compare' }, this.formatPrice(this.compare_at_price)), this.set_qty && !this.price_varies ? _createElement('div', {
                    'className': 'price-item--set',
                    'key': '1476'
                }, '\n            Set of ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n          ') : null) : null, !this.on_sale ? _createElement('div', {
                    'className': 'product-price',
                    'key': '1678'
                }, this.price_varies ? _createElement('span', { 'key': '1738' }, 'From: ') : null, _createElement('span', { 'className': 'price' }, this.formatPrice(this.price)), this.set_qty && this.price_varies ? _createElement('div', {
                    'className': 'price-item--set',
                    'key': '1862'
                }, '\n            Set of ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n          ') : null) : null, [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '20630'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI1.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], _createElement('div', { 'className': 'product-card_action' }, this.out_of_stock ? _createElement('span', { 'key': '2512' }, _createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'x': '0px',
                    'y': '0px',
                    'width': '24',
                    'height': '24',
                    'viewBox': '0 0 24 24'
                }, _createElement('path', {
                    'd': 'M11 0.7H13V23.3H11z',
                    'transform': 'rotate(-45.001 12 12)'
                }), _createElement('path', {
                    'd': 'M0.7 11H23.3V13H0.7z',
                    'transform': 'rotate(-45.001 12 12)'
                }))) : null, this.variant_ids.length > 1 && !this.out_of_stock ? _createElement('a', {
                    'href': this.url,
                    'title': 'Select options',
                    'key': '2865'
                }, _createElement('svg', {
                    'className': 'svg-setting',
                    'width': '25',
                    'height': '25',
                    'viewBox': '0 0 512.002 512.002'
                }, _createElement('use', { 'xlinkHref': '#svg-setting' }))) : null, this.variant_ids.length <= 1 && !this.out_of_stock ? _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'encType': 'multipart/form-data',
                    'key': '3160'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'quantity',
                    'value': this.set_qty || 1
                }), _createElement('button', {
                    'className': 'btn btn-add-to-cart',
                    'data-quick-buy': true,
                    'data-variant-id': this.variant_ids
                }, _createElement('svg', {
                    'className': 'svg-cart',
                    'width': '25',
                    'height': '25',
                    'viewBox': '0 0 512.002 512.002'
                }, _createElement('use', { 'xlinkHref': '#svg-cart' })))) : null)), this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '3785'
                }) : null)));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []